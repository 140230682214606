import React from "react";
const BoardMember = (props) => {
  return (
    <div className="BoardMember"
    >
      <div
        style={{
          display: "block",
          height: "230px",
          backgroundColor: "#f5f8f9",
          backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border:'1px solid #eee'
        }}
        className="BoardMemberThumb"
      ></div>
      <h3
        style={{
          paddingTop: "15px",
          paddingBottom: "0px",
          //   paddingLeft:'15px',
          //   paddingRight:'15px',
          fontWeight: "500",
          color:"#545454"
        }}
      >
        {props.name}
      </h3>
      <p
        style={{
          lineHeight: "120%",
          color: "#999",
          fontWeight:'300'
        }}
      >
        {props.jobTitle}
      </p>
    </div>
  );
};

export default BoardMember;
