import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Switch,
  Form,
  Button,
  message,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import { Editor } from "@tinymce/tinymce-react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  getStorage,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import moment from "moment";

const EditPostData = (props) => {
  const editorRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState([]);
  const [coverUrl, setCoverUrl] = useState();
  const [showTags, setShowTags] = useState(true);
  const [editorData, setEditorData] = useState();
  const [coverData, setCoverData] = useState([]);

  // Writing data to firebase
  const storage = getStorage();
  const getPost = async () => {
    const PostDoc = doc(db, "posts", props?.postId);
    const response = await getDoc(PostDoc);
    const body = response.data();

    if (body.postThumbnail) {
      try {
        const thumbRef = ref(storage, body.postThumbnail);
        const thumbMeta = await getMetadata(thumbRef);
        form.setFieldsValue({
          postThumbnail: [
            {
              uid: "-1",
              name: thumbMeta.name,
              status: "done",
              url: body.postThumbnail,
            },
          ],
        });
        setThumbnailUrl(body.postThumbnail);
      } catch (error) {
        setThumbnailUrl();
        console.log(error);
      }
    }

    if (body.postCover) {
      try {
        const coverRef = ref(storage, body.postCover);
        const coverMeta = await getMetadata(coverRef);
        form.setFieldsValue({
          postCover: [
            {
              uid: "-1",
              name: coverMeta.name,
              status: "done",
              url: body.postCover,
            },
          ],
        });
        setCoverUrl(body.postCover);
      } catch (error) {
        setCoverUrl();
        console.log(error);
      }
    }

    setEditorData(body.postBody);

    form.setFieldsValue({
      postDate: moment(body.postDate),
      postTitle: body.postTitle,
      postCategory: body.postCategory,
      postStatus: body.postStatus,
    });
  };

  useEffect(async () => {
    if (props.postId) {
      await getPost();
    }
  }, []);

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `images/${file.name}`);
    let response = await uploadBytes(imageRef, file);
    let imageUrl = await getDownloadURL(response.ref);
    return imageUrl;
  };

  const uploadThumbail = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `thumbnails/${file.name}`);
    try {
      let response = uploadBytesResumable(imageRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setThumbnailUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };

  const onRemoveThumbnail = async (file) => {
    const imageRef = ref(storage, `thumbnails/${file.name}`);
    const response = await deleteObject(imageRef);
    console.log(response);
  };

  const uploadCover = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `covers/${file.name}`);
    try {
      let response = uploadBytesResumable(imageRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setCoverUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };

  const updatePost = async (payload) => {
    try {
      const PostDoc = doc(db, "posts", props?.postId);
      await updateDoc(PostDoc, payload);
      message.success("Амжилттай засагдлаа");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onFinish = async (values) => {
    try {
      if (editorRef.current) {
        values.postBody = editorRef.current.getContent() || null;
      }

      values.postThumbnail = thumbnailUrl || null;
      values.postCover = coverUrl || null;
      values.postDate = values.postDate.format("YYYY-MM-DD");

      await updatePost(values);

      props.forceUpdate();
      props.onCancel();
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const onChange = async (values) => {
    if (values?.postCategory) {
      if (values?.postCategory == "CaseStudy") {
        setShowTags(false);
      } else {
        setShowTags(true);
      }
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          postTags: null,
        }}
        onValuesChange={onChange}
      >
        <Row gutter={[24, 24]}>
          <Col md={{ span: 17 }} sm={{ span: 24 }}>
            <Form.Item name="postTitle">
              <Input placeholder="Нийтлэлийн гарчиг" name="postTitle" />
            </Form.Item>
            <Form.Item>
              <Editor
                apiKey="gmyqqtvhl1sy9okzfgo00p917ljdiypq0oo94gff6zunj1a0"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue={editorData}
                init={{
                  menubar: true,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  plugins: "link image code",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | image help",
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = async function () {
                        let imageUrl = await uploadImage(file);
                        cb(imageUrl, { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                    input.click();
                  },
                }}
              />
            </Form.Item>
          </Col>

          <Col md={{ span: 7 }} sm={{ span: 24 }}>
            <Form.Item name="postCategory">
              <Select>
                <Option value="PressNews">Хэвлэлийн мэдээ</Option>
                <Option value="CaseStudy">Онцлох туршлага</Option>
                <Option value="SocialCare">Нийгмийн хариуцлага</Option>
              </Select>
            </Form.Item>
            <Form.Item name="postTags" hidden={showTags}>
              <Input placeholder="Түрхүүр үг" name="postTags" />
            </Form.Item>
            <Form.Item name="postDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="postStatus"
              label="Нийтлэл төлөв"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Идэвхитэй"
                unCheckedChildren="Идэвхигүй"
                defaultChecked={true}
              />
            </Form.Item>
            <Form.Item
              name="postThumbnail"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture"
                maxCount={1}
                customRequest={uploadThumbail}
                // onRemove={onRemoveThumbnail}
              >
                <Button icon={<UploadOutlined />}>Thumbnail (Max: 1)</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="postCover"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture"
                maxCount={1}
                customRequest={uploadCover}
              >
                <Button icon={<UploadOutlined />}>Cover (Max: 1)</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditPostData;
