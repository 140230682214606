import React from "react";

const PageHeaderMogul = (props) => {
  return (
    <>
      <div className="PageHeaderMogul">
        <div
          className="ptCover"
          style={{
            backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>

        <div className="centering-wrapper">
          <h2>{props.title}</h2>
        </div>
      </div>
    </>
  );
};

export default PageHeaderMogul;
