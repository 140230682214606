import React from "react";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
const HomeSlider = () => {
  const settingHomeSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    /* hsarrow-remove энэ класс Нүүр хуудасны баннерны хөндлөн скролл арилгах */

    <div className="homePageSlick">
      <Slider {...settingHomeSlider}>
        <SliderItem
          title=""
          imgurl="https://itzone.mn/photos/shares/mogul-web/slide-sample1.jpg"
        />

        <SliderItem
          title=""
          imgurl="https://itzone.mn/photos/shares/mogul-web/slider-2.jpg"
        />
      </Slider>
      {/* Scroll down mouse icon */}
      <div className="scrollDownIcon"></div>
    
    </div>
  );
};

export default HomeSlider;
