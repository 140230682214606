import { Col, Row, Button } from "antd";
import React, { useEffect, useState, useReducer } from "react";
import ArticleHeader from "./ArticleHeader";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";
import parse from "html-react-parser";
import EditPost from "../../post/EditPost";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FacebookFilled, TwitterOutlined } from "@ant-design/icons";

const NewsReading = () => {
  let loggedIn;
  if (JSON.parse(localStorage.getItem("auth"))) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    loggedIn = auth.loggedIn;
  }

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [searchParams, setSearchParams] = useSearchParams();
  const postId = searchParams.get("id");

  // хуудас ачааллахад дээд хэсэгтээ байрших
  useEffect(async () => {
    if (postId) {
      const PostDoc = doc(db, "posts", postId);
      const response = await getDoc(PostDoc);
      const body = response.data();

      setData({ ...body, postBody: parse(body?.postBody) });
    } else {
      navigate("/media");
    }
    window.scrollTo(0, 0);
  }, [reducerValue]);
  // төгсгөл хуудас ачааллахад дээд хэсэгтээ байрших

  let category = null;
  switch (data?.postCategory) {
    case "PressNews":
      category = "Хэвлэлийн мэдээ";
      break;
    case "CaseStudy":
      category = "Онцлох туршлага";
      break;
    case "SocialCare":
      category = "Нийгмийн хариуцлага";
      break;
  }

  const editPost = loggedIn ? (
    <EditPost forceUpdate={forceUpdate} postId={postId} />
  ) : null;

  return (
    <div>
      {editPost}
      <ArticleHeader
        title={data?.postTitle}
        category={category}
        date={data?.postDate}
        imgurl={data?.postCover}
      />

      <div className="article-reading-page">
        <div className="centering-wrapper">
          <Row gutter={[24, 24]}>
            <Col
              xl={{ span: 6 }} //breakpoint 1200px
              lg={{ span: 6 }} //breakpoint 992px
              md={{ span: 6 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <div className="sticky-article-option">
                <Button
                  icon={<FacebookFilled />}
                  style={{
                    background: "#4267B2",
                    color: "#fff",
                    borderColor: "#4267B2",
                    width: "48%",
                  }}
                >
                  SHARE
                </Button>
                <Button
                  icon={<TwitterOutlined />}
                  style={{
                    background: "#00acee",
                    color: "#fff",
                    borderColor: "#00acee",
                    width: "48%",
                  }}
                >
                  TWEET
                </Button>
              </div>
            </Col>

            <Col
              xl={{ span: 18 }} //breakpoint 1200px
              lg={{ span: 18 }} //breakpoint 992px
              md={{ span: 18 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              <article className="mogul-article">
                <p>{data?.postBody}</p>
              </article>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default NewsReading;
