import { Col, Row } from "antd";
import React from "react";
const OverviewIntro = () => {
  return (
    <div className="overview-intro">
      <div className="centering-wrapper">
        <Row>
          <Col
            xl={{ span: 8 }} //breakpoint 1200px
            lg={{ span: 8 }} //breakpoint 992px
            md={{ span: 12 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <h1 className="stickyTitle">Группийн тухай</h1>
          </Col>
          <Col
            xl={{ span: 16 }} //breakpoint 1200px
            lg={{ span: 16 }} //breakpoint 992px
            md={{ span: 12 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <p
              style={{
                textAlign: "justify",
                color: "#999",
              }}
            >
              1997 онд компьютер, түүний дагалдах тоног төхөөрөмжийн худалдаа,
              мэргэжлийн үйлчилгээ үзүүлэх зорилгоор анх үйл ажиллагаагаа
              эхлүүлсэн Могул групп нь Мэдээллийн Технологийн чиглэлээр төрөлжин
              үйл ажиллагаа явуулдаг 6 компанитайгаар 25 дахь жилдээ амжилттай
              ажиллаж байна. Бид “Хамтдаа бүтээх үнэ цэн” хэмээх нэгдмэл үнэт
              зүйл, итгэлцэл бүхий 380 гаруй ажилтантай ба ажилтнуудын 60 гаруй
              хувь нь мэргэшсэн инженерүүд байдаг.
            </p>
            <p
              style={{
                textAlign: "justify",
                color: "#999",
              }}
            >
              Мэдээллийн технологийн дэд бүтэц, тоног төхөөрөмжийн худалдаа,
              үйлчилгээ, Мэдээллийн болон биет аюулгүй байдал, Программ хангамж
              үйлдвэрлэл, Цахим засаг, Клауд болон Менежед үйлчилгээ, Дата болон
              AI, салбаруудын мэдээллийн технологийн шийдэл чиглэлээр үйл
              ажиллагаа явуулдаг ба тус салбарын зах зээлийн 30 гаруй хувийг
              дангаар эзэлсэн тэргүүлэгч групп компани юм. Могул групп нь 50
              гаруй үндэсний хэмжээний бүтээн байгуулалт, мэдээллийн технологийн
              томоохон мега төсөл хөтөлбөрүүдийг мэргэжлийн түвшинд хийж
              гүйцэтгэсэн туршлагатай бөгөөд мэдээллийн технологид суурилсан
              хөгжлийг авчрах Дижитал Шилжилтийн итгэлт түнш нь байж ирээдүйд
              үлдэх үнэ цэнийг хамтдаа бүтээхээр ажиллаж байна.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OverviewIntro;
