import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import FeaturedNews from "./FeaturedNews";
import FeaturedSmall from "./FeaturedSmall";

const NewsBox = (props) => {
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    if (props?.featuredPosts.length > 0) {
      setData(props?.featuredPosts[0]);
      setListData(props?.featuredPosts.slice(1, 5));
    }
  }, [props?.featuredPosts]);

  const featuredNews = (
    <FeaturedNews
      imgurl={data?.postThumbnail}
      date={data?.postDate}
      title={data?.postTitle}
      id={data?.id}
    />
  );
  return (
    <div className="NewsBox">
      <div className="centering-wrapper">
        <Row gutter={[24, 24]}>
          <Col
            xl={{ span: 16 }} //breakpoint 1200px
            xs={{ span: 24 }} //breakpoint 480px
          >
            {featuredNews}
          </Col>

          <Col
            xl={{ span: 8 }} //breakpoint 1200px
            xs={{ span: 24 }} //breakpoint 480px
          >
            {listData?.map((row, index) => {
              if (index > 0 || index < 5)
                return (
                  <FeaturedSmall
                    date={row.postDate}
                    title={row.postTitle}
                    id={row.id}
                  />
                );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsBox;
