import React, { useRef } from "react";
import {
  Input,
  Form,
  Button,
  message,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase/firebase-config";
import { Editor } from "@tinymce/tinymce-react";

const CareerForm = (props) => {
  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const { Option } = Select;

  // Writing data to firebase
  const CareersCollectionRef = collection(db, "careers");
  const createCareer = async (payload) => {
    try {
      await addDoc(CareersCollectionRef, payload);
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      console.log(error.message);
      message.error("Амжилтгүй");
    }
  };

  const onFinish = async (values) => {
    try {
      if (editorRef.current) {
        values.careerBody = editorRef.current.getContent() || null;
      }
      if (values.date) {
        const start = values.date[0].format("YYYY-MM-DD");
        const end = values.date[1].format("YYYY-MM-DD");

        values.date = [start, end];
      }

      await createCareer(values);

      form.resetFields();
      props.forceUpdate();
      props.onCancel();
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `images/${file.name}`);
    let response = await uploadBytes(imageRef, file);
    let imageUrl = await getDownloadURL(response.ref);
    return imageUrl;
  };

  const { RangePicker } = DatePicker;
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 14 }}
        labelAlign="left"
        layout="vertical"
      >
        <Row gutter={[24, 24]}>
          <Col md={{ span: 17 }} sm={{ span: 24 }}>
            <Form.Item
              name="jobTitle"
              label="Ажлын байрны нэр:"
              style={{ marginBottom: "12px" }}
            >
              <Input placeholder="Ажлын байрны нэр" name="jobTitle" />
            </Form.Item>
            <Form.Item>
              <Editor
                apiKey="gmyqqtvhl1sy9okzfgo00p917ljdiypq0oo94gff6zunj1a0"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue="<p>Агуулга оруулах хэсэг...</p>"
                init={{
                  menubar: true,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  plugins: "link image code table",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
                    "removeformat | image help",
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = async () => {
                        let imageUrl = await uploadImage(file);
                        cb(imageUrl, { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                    input.click();
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Col md={{ span: 7 }} sm={{ span: 24 }}>
            <Form.Item
              name="company"
              label="Компани:"
              style={{ marginBottom: "12px" }}
            >
              <Select placeholder="Компани сонгох">
                <Option value="ITZONE LLC">ITZONE LLC</Option>
                <Option value="NovelSoft LLC">NovelSoft LLC</Option>
                <Option value="Mogul Service & Support LLC">
                  Mogul Service & Support LLC
                </Option>
                <Option value="Mogul Express LLC">Mogul Express LLC</Option>
                <Option value="Digital Power LLC"></Option>
                <Option value="Digital Works LLC"></Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="vacancy"
              label="Ажлын байрны орон тоо"
              style={{ marginBottom: "12px" }}
            >
              <Select placeholder="Орон тоо">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="date"
              label="Анкет / Нийтлэгдэх хугацаа"
              style={{ marginBottom: "12px" }}
            >
              <RangePicker name="date" />
            </Form.Item>

            <Form.Item style={{ margin: "30px 0" }}>
              <Button type="primary" htmlType="submit">
                Ажлын зар үүсгэх
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CareerForm;
