import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import EnterPostData from "./EnterPostData";
import { PlusOutlined } from "@ant-design/icons";

const AddPost = (props) => {
  const [visible, setVisible] = useState(false);

  const showPopupAddPost = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        right: "50px",
        bottom: "50px",
      }}
    >
      <Button onClick={showPopupAddPost}>
        <PlusOutlined /> Нийтлэл нэмэх
      </Button>
      <Modal
        centered
        visible={visible}
        title="Шинэ нийтлэл нэмэх"
        width={1000}
        onCancel={onCancel}
        footer={null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
      >
        <EnterPostData forceUpdate={props.forceUpdate} onCancel={onCancel}/>
      </Modal>
    </div>
  );
};

export default AddPost;
