import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext({});

const AuthProvider = (props) => {
  const login = (email) => {
    localStorage.setItem("auth", JSON.stringify({
      email: email,
      loggedIn: true
    }));
  }

  const logout = () => {
    localStorage.setItem("auth", JSON.stringify({
      email: null,
      loggedIn: false
    }));
  }

  const AuthContextValue = {
      login,
      logout
  };

  return <AuthContext.Provider value={AuthContextValue} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
