import { FormOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useSearchParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase/firebase-config";
import parse from "html-react-parser";

const JobDetails = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const careerId = searchParams.get("id");

  const navigate = useNavigate();
  const [data, setData] = useState();

  useEffect(async () => {
    if (careerId) {
      const CareerDoc = doc(db, "careers", careerId);
      const response = await getDoc(CareerDoc);
      const body = response.data();

      if (body.careerBody) {
        setData({ ...body, careerBody: parse(body.careerBody) });
      } else {
        setData(body);
      }
    } else {
      navigate("/career/open-positions");
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#101010", padding: "150px 0" }}>
      <div className="centering-wrapper">
        <Row gutter={[0, 0]}>
          <Col
            xl={{ span: 10 }} //breakpoint 1200px
            lg={{ span: 10 }} //breakpoint 992px
            md={{ span: 10 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <div
              className="jobDetailsTitle"
            >
              <h5>Нээлттэй ажлын байр</h5>
              <h1>
                {data?.jobTitle}
              </h1>
              <h4>Компани: <span style={{fontWeight:"700"}}>{data?.company}</span></h4>
            </div>
          </Col>

          <Col
            xl={{ span: 14 }} //breakpoint 1200px
            lg={{ span: 14 }} //breakpoint 992px
            md={{ span: 14 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <div className="jobDetailsbody">
              {data?.careerBody}
              
              <Button
                size="large"
                icon={<RollbackOutlined />}
                style={{ marginRight: "10px" }}
                onClick={() => navigate(-1)}
              >
                Буцах
              </Button>
              <Button
                size="large"
                type="primary"
                icon={<FormOutlined />}
                onClick={() => navigate(`/job-application/${careerId}`)}
              >
                Анкет бөглөх
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default JobDetails;
