import React from "react";
import { Parallax } from "react-parallax";

// const image1 = "https://itzone.mn/photos/shares/mogul-web/plane_wing.jpg";

const ParallaxHeader = (props) => (
  <Parallax bgImage={props.image} strength={250}>
    <div className="parallaxHeader">
      <div className="centering-wrapper">
        <h2>{props.title}</h2>
      </div>
    </div>
  </Parallax>
);
export default ParallaxHeader;
