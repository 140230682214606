import { ArrowRightOutlined, GlobalOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};


const CompanyProfileBox = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    let itemList = []
    let list = props.list;
    if(list) {
      list.forEach((item,index)=>{
        itemList.push( <li key={index}>{item}</li>)
      })
      setList(itemList);
    }
  }, []);

  return (
    <div className="CompanyProfileBox">
      <div
        style={{
          display: "block",
          height: "200px",
          backgroundColor: "#f9f9f9",
          backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <h2
        style={{
          padding: "24px 36px 0",
          margin: "0 0",
          lineHeight: "120%",
          fontSize:"14pt",
          color: "#0555CC",
        }}
      >
        {props.company}
      </h2>
      <h4 style={{ padding: "0px 36px", margin: "0 0 12px", color: "#666" }}>
        {props.slogan}
      </h4>
      <ul>
        {list}
      </ul>

 
      {/* <Link to={{ pathname: props.link}} target="_blank">About</Link> */}
      <a href={props.link} target="_blank" className="weblink-out">Вэб үзэх <ArrowRightOutlined /> </a>
    </div>
  );
};

export default CompanyProfileBox;
