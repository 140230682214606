import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Helmet } from "react-helmet";
import Favicon from "./images/earth_icon.ico";
import Navigation from "./Nagivation/Navigation";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mogul Group - Creating Value Together</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="shortcut icon" href={Favicon} />
      </Helmet>
      <Router>
        <Navigation />
      </Router>
    </div>
  );
}

export default App;
