import React, { useEffect } from "react";

const SecurityTerms = () => {
  // хуудас ачааллахад дээд хэсэгтээ байрших
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // төгсгөл хуудас ачааллахад дээд хэсэгтээ байрших

  return (
    <div
      style={{
        backgroundColor: "#333",
        padding: "150px 0",
      }}
    >
      <div className="centering-wrapper">
        <div className="PolicyPage">
          <h1>Нууцлалын бодлого</h1>

          <p>
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. Его популяризации в новое время послужили публикация листов
            Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее
            время, программы электронной вёрстки типа Aldus PageMaker, в
            шаблонах которых используется Lorem Ipsum.
          </p>

          <h3>Дэд гарчиг</h3>
          <p>
            Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский
            набор слов, но это не совсем так. Его корни уходят в один фрагмент
            классической латыни 45 года н.э., то есть более двух тысячелетий
            назад. Ричард МакКлинток, профессор латыни из колледжа
            Hampden-Sydney, штат Вирджиния, взял одно из самых странных слов в
            Lorem Ipsum, "consectetur", и занялся его поисками в классической
            латинской литературе. В результате он нашёл неоспоримый
            первоисточник Lorem Ipsum в разделах 1.10.32 и 1.10.33 книги "de
            Finibus Bonorum et Malorum" ("О пределах добра и зла"), написанной
            Цицероном в 45 году н.э. Этот трактат по теории этики был очень
            популярен в эпоху Возрождения. Первая строка Lorem Ipsum, "Lorem
            ipsum dolor sit amet..", происходит от одной из строк в разделе
            1.10.32
          </p>
 
          <p>
            Lorem Ipsum - это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. Его популяризации в новое время послужили публикация листов
            Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее
            время, программы электронной вёрстки типа Aldus PageMaker, в
            шаблонах которых используется Lorem Ipsum.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecurityTerms;
