import { Col, Row } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import Slider from "react-slick/lib/slider";
import QuoteItem from "./QuoteItem";
import AddTestimonial from "../../components/Testimonial/AddTestimonial";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import parse from "html-react-parser";

const Testimonials = () => {
  let loggedIn;
  if(JSON.parse(localStorage.getItem("auth"))){
    const auth = JSON.parse(localStorage.getItem("auth"));
    loggedIn = auth.loggedIn
  }
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [data, setData] = useState();

  const settingQuotes = {
    dots: true, 
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const TestimonialsCollectionRef = collection(db, "testimonials");
  useEffect(async () => {
    let testimonials = await getDocs(TestimonialsCollectionRef);
    setData(testimonials.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }, [reducerValue]);

  const addTestimonial = loggedIn ? (
    <AddTestimonial forceUpdate={forceUpdate} />
  ) : null;

  return (
    <div className="hrTestimonials">
      <div className="centering-wrapper">
        <Row gutter={[24, 24]}>
         

          <Col md={{ span: 24 }} sm={{ span: 24 }}>
            <Slider {...settingQuotes}>
              {data?.map((row, index) => {
                return (
                  <QuoteItem
                    imgurl={row.thumbnail}
                    quote={row.quote ? parse(row.quote) : ""}
                    name={row.name}
                    jobtitle={row.jobTitle}
                  />
                );
              })}
            </Slider>
            {addTestimonial}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Testimonials;
