import { BackwardOutlined, FrownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="PageNotFound">
      <div className="centering-wrapper" style={{ textAlign: "center" }}>
        <h1 className="float"><FrownOutlined /></h1>
        <h1>404</h1>
        <h3>Уучлаарай, таны хайсан хуудас олдсонгүй...</h3>
        <Link to="/">
          <Button type="primary">Нүүр хуудас</Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
