import { Col, Row } from "antd";
import React from "react";

const StudentFull = () => {
  return (
    <div className="student">
      <header></header>

      <div className="intro">
        <div className="centering-wrapper">
        <h1>ОЮУТНЫ ДАДЛАГА</h1>
          <p style={{ marginBottom: "100px" }}>
            Оюутан, суралцагч танхимын сургалтаар эзэмшсэн онолын мэдлэг,
            чадвараа практик үйл ажиллагаатай холбон бататган гүнзгийрүүлж,
            ажиглалт, судалгаа, боловсруулалт хийх чадвар, дадал эзэмших, өөрийн
            мэргэжлийн салбарт тавигдаж буй зорилт, тулгамдсан асуудлын талаар
            тодорхой мэдээлэл олж авах, хөдөлмөр эрхлэхэд шаардлагатай ур
            чадваруудыг эзэмших зорилготой суралцах үйл ажиллагааг хэлнэ. Бид
            Авьяаслаг оюутнуудтай эртнээс харилцаа холбоо тогтоож, суралцаж байх
            хугацаанаас нь эхлэн хамтран ажиллах боломж олгохоос гадна компанид
            ажиллах боломжийг олгодог.
          </p>

          <Row gutter={24} type="flex">
            <Col
              xl={{ span: 12 }} //breakpoint 1200px
              lg={{ span: 12 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 12 }} //breakpoint 480px
            >
              <div className="zorilgo">
                <h3>Зорилго</h3>
                <p>
                  Оюутан залууст, сурсан мэдлэгийг нь бизнесийн нөхцөл байдалтай
                  уялдуулан, ажлын байрны бодит туршлагыг хуримтлуулах,
                  компанийн соёлд суралцах, ур чадварыг хөгжүүлэх боломжийг
                  олгоно.
                </p>
              </div>
            </Col>

            <Col
              xl={{ span: 12 }} //breakpoint 1200px
              lg={{ span: 12 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 12 }} //breakpoint 480px
            >
              <div className="student-terms">
                <h3>Дадлагын оюутныг сонгох ерөнхий нөхцөл</h3>
                <p>
                  Компанийн сонгон шалгаруулалтын процессыг хялбаршуулсан
                  байдлаар дадлагын оюутанг сонгон шалгаруулж компанид дадлага
                  хийлгэнэ. Дадлага хийх сонирхолтой оюутан, суралцагчид
                  компанид хэрэгцээт байгаа дадлагын оюутны зарыг нээлттэй ажлын
                  байр хэсгээс танилцах боломжтой.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="student-request">
        <div className="centering-wrapper">
          <Row gutter={36} type="flex">
            <Col
              xl={{ span: 12 }} //breakpoint 1200px
              lg={{ span: 12 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 12 }} //breakpoint 480px
            >
              <img src="https://itzone.mn/photos/shares/mogul-web/students-with-laptop-2021-09-24-03-35-53-utc.jpg" />
            </Col>

            <Col
              xl={{ span: 12 }} //breakpoint 1200px
              lg={{ span: 12 }} //breakpoint 992px
              md={{ span: 12 }} //breakpoint 768px
              xs={{ span: 12 }} //breakpoint 480px
            >
              <h1>Дадлага хийхэд бүрдүүлэх материал, ерөнхий шаардлага</h1>

              <ul>
                <li>
                  Компанид хэрэгцээт мэргэжлээр ИДС, Коллеж, МСҮТ болон бусад
                  төрлийн сургалтын төвүүдэд суралцагч
                </li>
                <li>Сурлагын дүн 2.8 буюу түүнээс дээш</li>
                <li>Ажлын байр хэсэгт анкетаа бөглөсөн байх</li>
                <li>Дадлагын удирдамж</li>
                <li>Сурлагын дүнгийн хуулбар</li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StudentFull;
