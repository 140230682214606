import { CalendarOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

const ArticleHeader = (props) => {
  return (
    <div className="mogul-article-header">
      <div className="article-coverimg"
        style={{
          backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")", 
        }}
      ></div>
      <div className="centering-wrapper">
        <Row>
          <Col
            xl={{ span: 12 }} //breakpoint 1200px
            lg={{ span: 12 }} //breakpoint 992px
            md={{ span: 12 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <a href="" className="article-category-link">
              {props.category}
            </a>
            <h1>{props.title}</h1>
            <h5><CalendarOutlined /> {props.date}</h5>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ArticleHeader;
