// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6inAzQ8BFz97ckNrFZrLD3zzOXd1HYK8",
  authDomain: "mogul-web.firebaseapp.com",
  projectId: "mogul-web",
  storageBucket: "mogul-web.appspot.com",
  messagingSenderId: "532485183387",
  appId: "1:532485183387:web:d286473b5ed464022cf33e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);