import React from "react";
import { Link } from "react-router-dom";

const CaseStudyCard = (props) => {
  const url = "/reading?id=" + props?.id;
  return (
    <Link to={url}>
      <div className="casestudycard">
        <p>{props.category}</p>
        <div
          style={{
            display: "block",
            height: "160px",
            backgroundColor: "#f5f8f9",
            backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginBottom: "15px",
          }}
        ></div>

        <a href="#">
          <h3>{props.title}</h3>
        </a>
      </div>
    </Link>
  );
};

export default CaseStudyCard;
