import React, { useEffect } from "react";
import HomeSlider from "../components/Homeslider/HomeSlider";
 
const Homepage = () => {

  // Хуудас нээгдэхэд хуудасний дээд ирмэгт аваачих
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // төгсгөл Хуудас нээгдэхэд хуудасний дээд ирмэгт аваачих

  return (
    <div>
      <HomeSlider />
        
    </div>
  );
};

export default Homepage;
