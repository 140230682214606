import { PhoneOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../Navbar/logo.svg";

const Navbar = () => {
  const [state, setstate] = useState(false);
  const changeClass = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 80) {
      setstate(true);
    } else {
      setstate(false);
    }
  };

  window.addEventListener("scroll", changeClass);

  return (
    <div>
      <header
        className={state ? "mogul-header-dark mogul-header" : "mogul-header"}
      >
        <div className="centering-wrapper">
          <a href="/">
            <img src={logo} className="logo" alt="mogul" />
          </a>
          <nav>
            <NavLink
              to="aboutus"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Бидний тухай
            </NavLink>
            <NavLink
              to="businessarea"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Салбар компаниуд
            </NavLink>
            <NavLink
              to="media"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              Медиа
            </NavLink>
            <NavLink
              to="career"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            > 
              Карьер
            </NavLink>
          </nav>

          <Link to="/contact" id="cta-btn">
            <Button type="primary" style={{marginRight:'-22px'}} >Холбоо барих</Button>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
