import React, { useRef, useState, useEffect } from "react";
import {
  Input,
  Form,
  Button,
  message,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase/firebase-config";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";

const CareerEditForm = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState();

  const getCareer = async () => {
    const CareerDoc = doc(db, "careers", props?.careerId);
    const response = await getDoc(CareerDoc);
    const body = response.data();

    if (body.careerBody) {
      setEditorData(body.careerBody);
    }
    if (body.date) {
      const start = moment(body.date[0]);
      const end = moment(body.date[1]);

      form.setFieldsValue({
        date: [start, end],
      });
    }

    form.setFieldsValue({
      jobTitle: body.jobTitle,
      company: body.company,
      vacancy: body.vacancy,
    });
  };

  useEffect(async () => {
    if (props.careerId) {
      await getCareer();
    }
  }, [props.onShow]);

  const updateCareer = async (payload) => {
    try {
      const CareerDoc = doc(db, "careers", props?.careerId);
      await updateDoc(CareerDoc, payload);

      message.success("Амжилттай засагдлаа");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onFinish = async (values) => {
    try {
      if (editorRef.current) {
        values.careerBody = editorRef.current.getContent() || null;
      }
      if (values.date) {
        const start = values.date[0].format("YYYY-MM-DD");
        const end = values.date[1].format("YYYY-MM-DD");

        values.date = [start, end];
      }

      await updateCareer(values);

      //   form.resetFields();
      props.forceUpdate();
      props.onCancel();
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `images/${file.name}`);
    let response = await uploadBytes(imageRef, file);
    let imageUrl = await getDownloadURL(response.ref);
    return imageUrl;
  };

  const { RangePicker } = DatePicker;
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        layout="vertical"
      >
        <Row gutter={[24, 24]}>
          <Col md={{ span: 17 }} sm={{ span: 24 }}>
            <Form.Item
              name="jobTitle"
              label="Ажлын байрны нэр:"
              style={{ marginBottom: "12px" }}
            >
              <Input placeholder="Ажлын байрны нэр" name="jobTitle" />
            </Form.Item>
            <Form.Item>
              <Editor
                apiKey="gmyqqtvhl1sy9okzfgo00p917ljdiypq0oo94gff6zunj1a0"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue={editorData}
                init={{
                  menubar: true,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  plugins: "link image code table",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
                    "removeformat | image help",
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = async () => {
                        let imageUrl = await uploadImage(file);
                        cb(imageUrl, { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                    input.click();
                  },
                }}
              />
            </Form.Item>
          </Col>
          <Col md={{ span: 7 }} sm={{ span: 24 }}>
            <Form.Item
              name="company"
              label="Компани:"
              style={{ marginBottom: "12px" }}
            >
              <Select placeholder="Компани сонгох">
                <Option value="ITZONE LLC">ITZONE LLC</Option>
                <Option value="NovelSoft LLC">NovelSoft LLC</Option>
                <Option value="Mogul Service & Support LLC">
                  Mogul Service & Support LLC
                </Option>
                <Option value="Mogul Express LLC">Mogul Express LLC</Option>
                <Option value="Digital Power LLC"></Option>
                <Option value="Digital Works LLC"></Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="vacancy"
              label="Ажлын байрны орон тоо"
              style={{ marginBottom: "12px" }}
            >
              <Select placeholder="Орон тоо">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="date"
              label="Анкет / Нийтлэгдэх хугацаа"
              style={{ marginBottom: "12px" }}
            >
              <RangePicker name="date" />
            </Form.Item>

            <Form.Item style={{ margin: "30px 0" }}>
              <Button type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CareerEditForm;
