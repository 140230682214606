import React from "react";
import { Parallax } from "react-parallax";

const image1 = "https://itzone.mn/photos/shares/mogul-web/plane_wing.jpg";

const Mission = () => (
  <Parallax
    bgImage={image1}
    strength={300}
    renderLayer={(percentage) => (
      <div>
        <div
          style={{
            position: "absolute",
            //   background: `rgba(17, 69, 234, ${percentage * 1})`,
            background: `rgba(17, 69, 234, 1)`,
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            width: percentage * 500,
            height: "1px",
          }}
        />
      </div>
    )}
  >
    <div className="mission">
      <h4>Эрхэм зорилго</h4>
      <h1>Хамтдаа бүтээх үнэ цэн</h1>
    </div>
  </Parallax>
);
export default Mission;
