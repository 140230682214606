import { FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Row, Modal } from "antd";
import React, { useState, useReducer, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";

import JobItem from "./JobItem";
import CareerForm from "../../components/Career/CareerForm";

const OpenPositions = () => {
  let loggedIn = false;
  if (JSON.parse(localStorage.getItem("auth"))) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    loggedIn = auth.loggedIn;
  }

  const [visible, setVisible] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [data, setData] = useState();

  const CareerCollectionRef = collection(db, "careers");
  useEffect(async () => {
    let careers = await getDocs(CareerCollectionRef);
    console.log(careers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setData(careers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }, [reducerValue]);

  const showPopupAddCareer = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };
  const AddCareer = loggedIn ? (
    <Button
      size="large"
      type="default"
      block
      icon={<FileAddOutlined />}
      style={{ margin: "30px 0" }}
      onClick={showPopupAddCareer}
    >
      Ажлын байрны зар нэмэх
    </Button>
  ) : null;

  return (
    <div>
      <section className="openpositionHeader">
        <div className="centering-wrapper">
          <h1>Нээлттэй ажлын байрууд</h1>
           
        </div>
      </section>

      <section style={{ padding: "30px 0 100px", backgroundColor:"#101010" }}>
        <div className="centering-wrapper">
          <Row gutter={[24, 24]}>
            <Col
              offset={0}
              xl={{ span: 24 }} //breakpoint 1200px
              lg={{ span: 24 }} //breakpoint 992px
              md={{ span: 24 }} //breakpoint 768px
              xs={{ span: 24 }} //breakpoint 480px
            >
              {data?.map((row, index) => {
                return (
                  <JobItem
                    loggedIn={loggedIn}
                    careerData={row}
                    forceUpdate={forceUpdate}
                  />
                  // <QuoteItem
                  //   imgurl={row.thumbnail}
                  //   quote={parse(row.quote)}
                  //   name={row.name}
                  //   jobtitle={row.jobTitle}
                  // />
                );
              })}

              {AddCareer}
            </Col>
          </Row>
          <Modal
            centered
            visible={visible}
            title="Нээлттэй ажлын байр зарлах"
            width={1000}
            onCancel={onCancel}
            footer={null}
            // cancelButtonProps={{ style: { display: 'none' } }}
            // okButtonProps={{ style: { display: 'none' } }}
          >
            <CareerForm forceUpdate={forceUpdate} onCancel={onCancel} />
          </Modal>
        </div>
      </section>
    </div>
  );
};

export default OpenPositions;
