import React, { useEffect, useState, useReducer } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CaseStudyList from "../components/Media/CaseStudyList";
import NewsBox from "../components/Media/News/NewsBox";
import SocialImpacts from "../components/Media/SocialImpacts/SocialImpacts";
import PageHeaderMogul from "../components/PageHeaderMogul";
import AddPost from "../components/post/AddPost";

import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../firebase/firebase-config";

const Media = () => {
  let loggedIn;
  if (JSON.parse(localStorage.getItem("auth"))) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    loggedIn = auth.loggedIn;
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [socialCares, setSocialCares] = useState([]);
  const [loading, setLoading] = useState(false);

  const PostsCollectionRef = collection(db, "posts");

  //featured posts
  const pressNewsQuery = query(
    PostsCollectionRef,
    orderBy("postDate", "desc"),
    where("postCategory", "==", "PressNews"),
    limit(5)
  );

  // case studies
  const caseStudiesQuery = query(
    PostsCollectionRef,
    orderBy("postDate", "desc"),
    where("postCategory", "==", "CaseStudy"),
    limit(3)
  );

  // social cares
  const socialCaresQuery = query(
    PostsCollectionRef,
    orderBy("postDate", "desc"),
    where("postCategory", "==", "SocialCare"),
    limit(1)
  );
  useEffect(async () => {
    setLoading(true);
    let news = await getDocs(pressNewsQuery);
    let cs = await getDocs(caseStudiesQuery);
    let sc = await getDocs(socialCaresQuery);
    setFeaturedPosts(news.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setCaseStudies(cs.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setSocialCares(sc.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]);
    setLoading(false);

    // хуудас ачааллахад дээд хэсэгтээ байрших
    window.scrollTo(0, 0);
  }, [reducerValue]);
  // төгсгөл хуудас ачааллахад дээд хэсэгтээ байрших
  // get data list from firebase

  // show post button if admin logged in
  const addPost = loggedIn ? <AddPost forceUpdate={forceUpdate} /> : null;
  let showSocialCares = null;

  if (socialCares) {
    showSocialCares = (
      <SocialImpacts
        imgurl={socialCares?.postThumbnail}
        title={socialCares?.postTitle}
        intro={socialCares?.postBody}
      />
    );
  }

  return (
    <>
      <PageHeaderMogul imgurl="" title="Media" />
      <Spin size="large" spinning={loading}>
        <NewsBox featuredPosts={featuredPosts} />
      </Spin>
      <Spin size="large" spinning={loading}>
        <CaseStudyList caseStudies={caseStudies} />
      </Spin>

      {showSocialCares}
      {/* Шинэ нийтлэл оруулах хэсэг админ нэвтэрч орсон хойно харагдана */}
      {addPost}
    </>
  );
};

export default Media;
