import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const FeaturedNews = (props) => {
  const url = "/reading?id=" + props?.id;
  return (
    <Link to={url}>
      <div className="FeaturedNews">
        <div
          style={{
            display: "block",
            height: "400px",
            backgroundColor: "#f5f8f9",
            backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginBottom: "30px",
          }}
        ></div>
        <h5>{props.date}</h5>
        <h2>{props.title}</h2>
      </div>
    </Link>
  );
};

export default FeaturedNews;
