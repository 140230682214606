import { Col, Divider, Row } from "antd";
import React from "react";
import BoardMember from "./BoardMember";

const BoardTeam = () => {
  return (
    <div className="board-members">
      <div className="centering-wrapper">
        <Row>
          <Col
            xl={{ span: 8 }} //breakpoint 1200px
            lg={{ span: 8 }} //breakpoint 992px
            md={{ span: 12 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <h1 className="stickyTitle">Удирдлагын баг</h1>
          </Col>
          <Col
            xl={{ span: 16 }} //breakpoint 1200px
            lg={{ span: 16 }} //breakpoint 992px
            md={{ span: 12 }} //breakpoint 768px
            xs={{ span: 24 }} //breakpoint 480px
          >
            <Row gutter={[24, 24]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/battulga_n.jpg"
                  name="Н.Баттулга"
                  jobTitle="Могул группийн Ерөнхийлөгч"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/naranbaatar_u.jpg"
                  name="У.Наранбаатар"
                  jobTitle="Ай Ти Зон компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/jargalsaikhan_b.jpg"
                  name="Б.Жаргалсайхан"
                  jobTitle="Могул группийн Үйл ажиллагаа хариуцсан захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/tseesuren_b.jpg"
                  name="Б.Цээсүрэн"
                  jobTitle="Новелсофт компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/otgonchimeg_d.jpg"
                  name="Д.Отгончимэг"
                  jobTitle="Могул группийн Санхүү хариуцсан захирал"
                />
              </Col>
            </Row>

            <h3
              style={{
                margin: "80px 0 0",
                lineHeight: "100%",
              }}
            >
              Салбар компаниудын удирдлага
            </h3>
            <Divider orientation="left" style={{ margin: "30px 0" }}></Divider>

            <Row gutter={[24, 24]}>
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/naranbaatar_u.jpg"
                  name="У.Наранбаатар"
                  jobTitle="Ай Ти Зон компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/tseesuren_b.jpg"
                  name="Б.Цээсүрэн"
                  jobTitle="Новелсофт компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/bolor-erdene_s.jpg"
                  name="С.Болор-Эрдэнэ"
                  jobTitle="Могул Сервис энд Саппорт компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/nyamtseren_b.jpg"
                  name="Б.Нямцэрэн"
                  jobTitle="Дижитал Воркс компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/adiya.jpg"
                  name="А.Адъяа"
                  jobTitle="Дижитал Повер компанийн Гүйцэтгэх захирал"
                />
              </Col>

              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 12 }} //breakpoint 480px>
              >
                <BoardMember
                  imgurl="https://itzone.mn/photos/shares/mogul-web/urandush_m.jpg"
                  name="М.Урандөш"
                  jobTitle="Могул Экспресс компанийн Гүйцэтгэх захирал"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BoardTeam;
