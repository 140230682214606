import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Navbar from "../components/Navbar";
import Homepage from "../pages/Homepage";
import AboutUs from "../pages/AboutUs";
import BusinessArea from "../pages/BusinessArea";
import Media from "../pages/Media";
import Career from "../pages/Career";
import Contact from "../pages/Contact";
import AdminLayout from "../pages/AdminPage/AdminLayout";
import NewsReading from "../components/Media/News/NewsReading";
import SecurityTerms from "../pages/termsAndPolicy/SecurityTerms";
import TermsofUse from "../pages/termsAndPolicy/TermsofUse";
import SiteMap from "../pages/termsAndPolicy/SiteMap";
import { useLocation } from "react-router-dom";
import StudentFull from "../pages/Career/StudentFull";
import NotFoundPage from "../pages/NotFoundPage";
import OpenPositions from "../pages/Career/OpenPositions";
import JobDetails from "../pages/Career/JobDetails";
import CareerForm from "../pages/CareerForm";
const Navigation = () => {
    const location = useLocation();
    const paths = ["adminstrator", "career-form", "job-application"];

    return (
        <>
            {paths.includes(location.pathname.split("/")[1]) ? null : <Navbar />}
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/aboutus" element={<AboutUs />}></Route>
                <Route path="/businessarea" element={<BusinessArea />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/media" element={<Media />}></Route>
                <Route path="/career" element={<Career />}></Route>
                <Route path="/reading" element={<NewsReading />}></Route>
                <Route path="/securityterms" element={<SecurityTerms />}></Route>
                <Route path="/termsofuse" element={<TermsofUse />}></Route>
                <Route path="/sitemap" element={<SiteMap />}></Route>
                <Route path="/" element={<Homepage />}></Route>
                <Route path="/career/student" element={<StudentFull />}></Route>
                <Route path="/career/open-positions" element={<OpenPositions />}></Route>
                <Route path="/career/job-details" element={<JobDetails />}></Route>
                <Route path="/administrator" element={<AdminLayout />}></Route>
                <Route path="/job-application/:id" element={<CareerForm />}></Route>

            </Routes>
            {paths.includes(location.pathname.split("/")[1]) ? null : <Footer />}
        </>
    );
};

export default Navigation;
