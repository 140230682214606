import React from "react";

const SliderItem = (props) => {
  return (
    <div>
      <div
        className="HomeSlider"
        style={{
          backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat" 
          
        }}
      >
        <div className="slider-gradient"></div>
        <div className="centering-wrapper">
          <div className="slider-context">
            <h1>{props.title}</h1>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default SliderItem;
