import { Col, Row } from "antd";
import React from "react";

const HistoryItem = (props) => {
  return (
    <div className="timelineItem">
      <div
        style={{
          display: "block",
          height: "220px",
          backgroundColor: "#333",
          backgroundImage: "url(" + (props.imgurl ? props.imgurl : "") + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border:"1px solid #999"
        }}
      ></div>

      <h1>{props.year}</h1>
      <h4>{props.title}</h4>
      <p>{props.detail}</p>
    </div>
  );
};

export default HistoryItem;
