import React, { useState } from "react";

import { ArrowRightOutlined, DeleteOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { Row, Col, Button, Modal, Popconfirm, message } from "antd";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { Link } from "react-router-dom";
import CareerEditForm from "../../components/Career/CareerEditForm";

const JobItem = (props) => {
  const url = "/career/job-details?id=" + props?.careerData.id;
  const [visible, setVisible] = useState();

  let loggedIn;
  if (JSON.parse(localStorage.getItem("auth"))) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    loggedIn = auth.loggedIn;
  }

  const showShow = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onDelete = async () => {
    try {
      const CareerDoc = doc(db, "careers", props?.careerData.id);
      await deleteDoc(CareerDoc);

      message.success("Амжилттай устгагдлаа");
      props.forceUpdate();
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const EditCareer = props?.loggedIn ? (
    <>
      <Button type="text" onClick={showShow}>
        <EditOutlined />
      </Button>
      <Popconfirm
        title="Ажлын зарыг устгах уу?"
        onConfirm={onDelete}
        okText="Тийм"
        cancelText="Үгүй"
      >
        <Button type="text">
          <DeleteOutlined />
        </Button>
      </Popconfirm>

      <Modal
        centered
        visible={visible}
        title="Ажлын байрны зар засах"
        width={1000}
        onShow={showShow}
        onCancel={onCancel}
        footer={null}
      >
        <CareerEditForm
          forceUpdate={props.forceUpdate}
          onCancel={onCancel}
          careerId={props?.careerData.id}
        />
      </Modal>
    </>
  ) : null;
  return (
    <div className="JobListItem">
      <Row gutter={[16, 16]}>
        <Col
          xl={{ span: 10 }} //breakpoint 1200px
          lg={{ span: 10 }} //breakpoint 992px
          md={{ span: 10 }} //breakpoint 768px
          xs={{ span: 24 }} //breakpoint 480px
        >
          <Link to={url}>
            <h3>{props?.careerData?.jobTitle}</h3>
          </Link>
        </Col>

        <Col
          xl={{ span: 8 }} //breakpoint 1200px
          lg={{ span: 8 }} //breakpoint 992px
          md={{ span: 8 }} //breakpoint 768px
          xs={{ span: 24 }} //breakpoint 480px
        >
          <h3 style={{color:'#999'}}>{props?.careerData?.company}</h3>
        </Col>

        <Col
          xl={{ span: 2 }} //breakpoint 1200px
          lg={{ span: 2 }} //breakpoint 992px
          md={{ span: 2 }} //breakpoint 768px
          xs={{ span: 24 }} //breakpoint 480px
        >
          <h3>{props?.careerData?.vacancy}</h3>
        </Col>

        <Col
          xl={{ span: 4 }} //breakpoint 1200px
          lg={{ span: 4 }} //breakpoint 992px
          md={{ span: 4 }} //breakpoint 768px
          xs={{ span: 24 }} //breakpoint 480px
        >
          {EditCareer}
          <Link to={url}>
          <Button type="primary" shape="round" icon={<ArrowRightOutlined />}   >Дэлгэрэнгүй </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default JobItem;
