import { Col, Row } from "antd";
import React from "react";

function QuoteItem(props) {
  return (
    <div className="quoteItem">
      <Row gutter={[36, 36]}>
        <Col md={{ span: 12 }} sm={{ span: 12 }}>
          <h2>{props.quote}</h2>
          <p>
            <span style={{ fontWeight: 600 }}>{props.name},</span>{" "}
            <span style={{ color:"#999" }}>{props.jobtitle}</span>
          </p>
        </Col>

        <Col md={{ span: 12 }} sm={{ span: 12 }}>
          <div
            className="quoteImg"
            style={{
              backgroundImage:
                "url(" + (props.imgurl ? props.imgurl : "") + ")",
            }}
          ></div>
        </Col>
      </Row>
    </div>
  );
}

export default QuoteItem;
