import { Col, Row } from "antd";
import React from "react";

const NumericStatsMogul = () => {
  return (
    <div style={{ padding: "100px 0" }}>
      <div className="centering-wrapper">
        <Row gutter={[24, 24]}>
          
          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>1997</h1>
              <h3>онд бизнесийн гараагаа эхлүүлсэн</h3>
            </div>
          </Col>

          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>6</h1>
              <h3>салбар компанийн тоо</h3>
            </div>
          </Col>
          

          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>13.5 тэрбум₮</h1>
              <h3>татварын орлогыг улсын төсөвт төвлөрүүлсэн (2021 онд)</h3>
            </div>
          </Col>

          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>380+ </h1>
              <h3>нийт ажилтны тоо</h3>
            </div>
          </Col>

          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>60%</h1>
              <h3>Инженер, програмистууд</h3>
            </div>
          </Col>

          <Col
            xl={{ span: 4 }} //breakpoint 1200px
            lg={{ span: 4 }} //breakpoint 992px
            md={{ span: 6 }} //breakpoint 768px
            xs={{ span: 12 }} //breakpoint 480px>
          >
            <div className="numericDataItem">
              <h1>+300 сая₮</h1>
              <h3>Сургалт хөгжүүлэлтийн жилийн зардал</h3>
            </div>
          </Col>
          

        </Row>
      </div>
    </div>
  );
};

export default NumericStatsMogul;
