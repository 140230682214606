import React, { useEffect } from 'react'

const TermsofUse = () => {
    // хуудас ачааллахад дээд хэсэгтээ байрших
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  // төгсгөл хуудас ачааллахад дээд хэсэгтээ байрших
  return (
    <div
      style={{
        backgroundColor: "#333",
        padding: "150px 0",
      }}
    >
      <div className="centering-wrapper">
        <div className="PolicyPage">
          <h1>Үйлчилгээний нөхцөл</h1>

          <p>
            We respect the privacy of everyone who visits our site. We do not
            collect personal information about our users without their knowledge
            and prior consent. No scripts or devices are used for this purpose.
            In order to use certain features of our website we may ask you to
            register by entering some personal information. This information is
            given voluntarily and will be used only as stated in the paragraphs
            below. This information will not be given, traded or sold to any
            third party without the user's knowledge and prior consent. The
            information provided upon registration, with the exception of your
            e-mail address, will be displayed on your user profile and we
            reserve the right to use it in other publications produced by
            Awwwards. By registering, you indicate your acceptance of these
            conditions. Further information may be provided at the user’s
            discretion but is not compulsory. When you submit a website for an
            Awwward, you will be asked to provide some information about the
            project. This information will be published on our site and we
            reserve the right to use it in other publications produced by
            Awwwards. By submitting your site, you indicate your acceptance of
            these conditions. We collect some information such as: 1) Pages
            visited, 2) Click stream data and 3) Other browsing information, for
            administrative purposes and to improve our website and services.
            Cookies: This website does not use cookies, but it does use third
            party technologies which use them to provide useful features and
            services. Cookies identify a user's computer, not the individual
            user. Most browsers are set to accept cookies by default. You can
            always set your browser to refuse cookies, but you may not be able
            to use all the features of our site. Children: Awwwards complies
            with the requirements of the Children's Online Privacy Protection
            Act. Awwwards will not knowingly accept submissions or
            correspondence from children under 13 years of age without written
            permission from their parents or legal guardians.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TermsofUse
