import React from "react";
import HistoryItem from "./Timeline/HistoryItem";
import Slider from "react-slick";

const HistorycalTimeline = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="history">
      <div className="centering-wrapper">
        <h1>Түүхэн замнал</h1>

        <Slider {...settings}>
          <HistoryItem
            year="1997"
            title="М-Си-Эс Электроникс"
            detail="1997 онд МУИС-ийн Компьютер Электроникийн сургуулийн багш, төгсөгч бүхий 10 залуус нийлэн Монголын мэдээллийн технологийн салбарт хүч үзэхээр зориглон, М-Си-Эс Группийн хөрөнгө оруулалттайгаар М-Си-Эс Электроникс компанийг байгуулав."
          />
          <HistoryItem
            imgurl="https://itzone.mn/photos/shares/mogul-web/mcse_store.jpg"
            year="2000"
            title="Дэлхийн томчууд Монголд албан ёсоор"
            detail="Чөлөөт эдийн засагт шилжээд 10 гаруйхан жил болж байсан тухайн үед дэлхийн брэндүүд, тэр дундаа мэдээллийн технологийн могулууд Монголын зах зээлийг огт сонирхдоггүй байв. Мэдээллийн технологи хөгжлийн шинэ гарц болохыг хэдийнээ харсан залуус тууштай зүтгэсний үр дүнд дэлхийн томоохон компаниудыг Монголд авчирч чадсан юм. Dell 2000 онд, Canon, HPE 2002 онд, Microsoft, Cisco, Oracle 2004 онд тус тус албан ёсоор бүтээгдэхүүн, шийдлүүдээ нийлүүлж эхэллээ."
          />
          <HistoryItem
            imgurl="https://itzone.mn/photos/shares/mogul-web/mogul_factorty.jpg"
            year="2005"
            title="Үндэсний технологийн брэнд"
            detail="Компанийн өрсөлдөх чадвар, хүний нөөцийн чадавх, туршлага хуримтлагдсан тухайн үед Үндэсний технологийн брэнд бүтээх санаачилга ажилтнууд дундаас төрөв. Санаачилга бодитоор хэрэгжиж, Монголын анхны компьютер ATHENA нэртэйгээр, худалдаанд гарч, 2 жилийн хугацаанд 15,000 ширхэг борлуулагдсан нь их урмыг өгч Mogul, GreenERP зэрэг дараа дараагийн үндэсний брэндүүдийг Монгол инженерүүд хамтдаа бүтээх эхлэл болов."
          />
          <HistoryItem
            year="2013"
            title="Могул группийн эхлэл"
            detail="2013 оны 5 сарын 1-ний өдөр М-Си-Эс Электроникс компанийг олон жил удирдсан багт нь итгэл хүлээлгэн менежментийн хувьчлалаар шилжүүлж, бие даасан компани болгох шийдвэрийг М-Си-Эс Группээс гаргав. Бүрэн бие даасан компани болсон тул компанийн нэр М-Си-Эс Электрониксоос Ай Ти Зон болж өөрчлөгдсөн нь өнөөдрийн Могул группийн эхлэл болсон юм."
          />
          <HistoryItem
            year="2015"
            title="Могул Сервис энд Саппорт компани байгуулагдав"
            detail="2015 онд Ай Ти Зон компанийн Техник Туслалцаа Үйлчилгээний төв-өөс өрх тусгаарлан гарсан мэргэжлийн сертификаттай инженер, сервис менежер, борлуулалтын мэргэжилтнүүдийн баг бүхий МТ-ийн урьтач үйлчилгээ үзүүлэгч Могул Сервис энд Саппорт компани байгуулагдав."
          />
          <HistoryItem
            year="2016"
            title="Могул Консалтинг энд Сервис компани байгуулагдав"
            detail="2016 онд Мэдээллийн технологид суурилан хүний нөөцийн функцийн бүх төрлийн зөвлөх болон аутсорсинг үйлчилгээ үзүүлэх зорилготой Могул Консалтинг энд Сервис компани байгуулагдав."
          />

          <HistoryItem
            year="2016"
            title="Могул Экспресс компани байгуулагдав"
            detail="2016 онд Мэргэжлийн суурилуулалт, Агуулах, Түгээлт, Гаалийн үйлчилгээ гэсэн 4 үндсэн чиглэлээр үйл ажиллагаа явуулах зорилготой Могул Экспресс компани байгуулагдав."
          />

          <HistoryItem
            year="2018"
            title="Дижитал Воркс компани байгуулагдав"
            detail="2010 оноос хойш Green ERP системийг хөгжүүлж эхэлсэн Ай Ти Зон компанийн ERP газар нь байгууллагын цогц ERP систем хөгжүүлэх, үйл ажиллагаа өргөжүүлэх зорилгоор 2018 онд Дижитал Воркс компани болон тусдаа гарав. "
          />

          <HistoryItem
            year="2019"
            title="Дижитал Повер компани байгуулагдав"
            detail="1999 онд барилга, уул уурхай болон үйлдвэрийн холбоо дохиолол, автоматжуулалтын тоног төхөөрөмжийн худалдаа, угсралт, үйлчилгээг хүргэх зорилгоор үйл ажиллагаагаа эхлүүлж байсан Систем Интеграцчлал Автоматжуулалтын газар нь 2018 онд тусдаа гарч Дижитал Повер компани байгуулагдав. "
          />
          <HistoryItem
            year="2020"
            title="Новелсофт компани байгуулагдав"
            detail="2003 оноос үйл ажиллагаагаа эхэлсэн Ай Ти Зон компанийн Програм хангамжийн газар болон Дата баг нь 2020 онд нэгдэн Захиалгат програм хангамжийн хөгжүүлэлт, Дата аналитик, Дата менежментийн чиглэлээр үйл ажиллагаа явуулах зорилготойгоор тусдаа гарч Новелсофт компани байгуулагдав. "
          />
          <HistoryItem
            year="2021"
            title="Могул групп өөрийн оффистой болов"
            detail="Өнгөрсөн хугацаанд тусдаа оффисуудад ажиллаж ирсэн Могул группийн 7 компаниуд нь цаашдын хамтын ажиллагааны бүтээмж, үр дүнгээ нэмэгдүүлэх, түүнчлэн Могулчуудынхаа Нэг оффист группээрээ хамтдаа ажиллах, Хамтдаа үнэ цэнийг бүтээх гэсэн олон жилийн хүслийг биелүүлэн 2021 онд Хан-Уул дүүргийн 15-р хороо, Хаан банк тауэрын 22-р давхарт өөрийн оффистой болов. "
          />

          <HistoryItem
            imgurl="https://www.itzone.mn/photos/shares/mogul-web/mcs_sign.jpg"
            year="2022"
            title="Могул Консалтинг энд Сервис компани бие даасан компани болов"
            detail="2022 онд Могул Консалтинг энд Сервис компани нь үйл ажиллагаагаа өргөжүүлэх зорилгоор менежментийн хувьчлал хийж Могул группээс өрх тусгаарлан бие даасан компани болов.
            Могул Консалтинг энд Сервис компани нь 2019 оноос эхлэн үйл ажиллагааны чиглэлээ нэмэн байгууллагын ERP системийг нэвтрүүлэх үйлчилгээг үзүүлж эхэлсэн ба Дижитал Воркс компанийн Санхүү, Хүний нөөц, Худалдан авалт, Борлуулалт болон Гэрээний системийг нэвтрүүлэх ажлыг хариуцан ажиллаж байна. "
          />

          <HistoryItem
            imgurl="https://www.itzone.mn/photos/shares/mogul-web/MDC_opening.jpg"
            year="2022"
            title="Могул Дата Центр байгуулагдав"
            detail="Могул групп нь 100% өөрийн хөрөнгөөр Улаанбаатар хот, Хан-Уул дүүрэг, Буянт ухаа нисэх буудлын зүүн урд талд МОГУЛ ДАТА ЦЕНТР-ийг барьж байгуулан 2022 онд ашиглалтад орууллаа. 
        Могул дата центрийн барилга байгууламж, цахилгаан, хөргөлт, сүлжээ зэрэг тоног төхөөрөмж, бүрэлдэхүүн хэсгүүдийг Uptime Institute-с гаргадаг Tier 3 стандартын шаардлагад нийцүүлэн Schneider Electric, Bosch, Asenware, Suprema зэрэг дэлхийд алдартай брэндийн баталгаат шийдэл, төхөөрөмжүүдээр тоноглосон юм."
          />
        </Slider>
      </div>
    </div>
  );
};

export default HistorycalTimeline;
