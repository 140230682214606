import { Row, Col, Divider } from "antd";
import React, { useState, useEffect } from "react";
import CaseStudyCard from "./CaseStudyCard";

const CaseStudyList = (props) => {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    if (props?.caseStudies.length > 0) {
      setListData(props?.caseStudies);
    }
  }, [props?.caseStudies]);
  return (
    <div
      style={{
        backgroundColor: "#f6f6f6",
      }}
    >
      <div
        className="centering-wrapper"
        style={{ paddingTop: "30px", paddingBottom: "100px" }}
      >
        <Divider style={{ marginBottom: "50px" }}>Онцлох төслүүдээс</Divider>
        <Row gutter={[24, 24]}>
          {listData?.map((row, index) => {
            return (
              <Col
                xl={{ span: 8 }} //breakpoint 1200px
                lg={{ span: 8 }} //breakpoint 992px
                md={{ span: 12 }} //breakpoint 768px
                xs={{ span: 24 }} //breakpoint 480px
              >
                <CaseStudyCard
                  category={row.postTags}
                  title={row.postTitle}
                  imgurl={row.postThumbnail}
                  id={row.id}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default CaseStudyList;
