import { Button, Col, Row } from "antd";
import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons/lib/icons";

const SocialImpacts = (props) => {
  return (
    <div style={{ backgroundColor: "#ccc", padding:'100px 0' }}>
      <div className="centering-wrapper">
        <div className="socialimpact-big">
          <Row gutter={[0, 0]}>
            <Col
              md={{ span: 12 }}
              sm={{ span: 24 }}
              style={{
                display: "block",
                minHeight: "400px",
                height: "100",
                backgroundColor: "#ccc",
                backgroundImage:
                  "url(" + (props.imgurl ? props.imgurl : "") + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></Col>

            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <div className="socialimpact-big-intro">
                <h3>Нийгмийн хариуцлага</h3>
                <h1>{props.title}</h1>
                <p>{props?.intro?.replaceAll(/(<([^>]+)>)/gi, "")}</p>
                <Button
                  type="primary"
                  size="large"
                  icon={<ArrowRightOutlined />}
                  style={{ marginTop: "50px" }}
                >
                  Бусад мэдээллийг харах
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SocialImpacts;
