import { SendOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import axios from "axios";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";

const SalesForceForm = () => {
  const [form] = Form.useForm();
  const ContactCollectionRef = collection(db, "contacts");
  const createContact = async (payload) => {
    try {
      await addDoc(ContactCollectionRef, payload);
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      console.log(error.message);
      message.error("Амжилтгүй");
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    // var bodyFormData = new FormData();
    // bodyFormData.append("936523_50613pi_936523_50613", values.name);
    // bodyFormData.append("936523_50611pi_936523_50611", values.email);
    // bodyFormData.append("936523_50615pi_936523_50615", values.company);
    // bodyFormData.append("936523_50617pi_936523_50617", values.content);

    try {
      await createContact(values);
      // let response = await axios({
      //   method: "post",
      //   url: "https://go.pardot.com/l/936523/2022-03-23/25ksx",
      //   data: bodyFormData,
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      //     'Access-Control-Allow-Credentials':true
      //   }
      // })

      // if(response){
      //   message.success("Амжилттай")
      // }
      form.resetFields();
      message.success("Амжилттай");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 8 },
    },
  };
  return (
    <div>
      <Form
        action="http://go.pardot.com/l/936523/2022-02-24/ydck"
        form={form}
        name="contact"
        {...formItemLayout}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Таны нэр"
          name="First_Namepi_First_Name"
          id="First_Namepi_First_Name"
          required
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input    name="First_Namepi_First_Name"  id="First_Namepi_First_Name" ></Input>
        </Form.Item>

        <Form.Item
          label="И-Мэйл хаяг"
          name="Emailpi_Email"
          id="Emailpi_Email"
          required
          rules={[
            { required: true, message: "Please input your email!" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input  name="Emailpi_Email"  id="Emailpi_Email"></Input>
        </Form.Item>

        <Form.Item
          label="Байгууллага"
          required
          name="company"
          id="company"
          rules={[{ required: true, message: "Please input your company!" }]}
        >
          <Input id="Companypi_Company" name="Companypi_Company"></Input>
        </Form.Item>
        <Form.Item
          required
          label="Захидал бичих"
          name="content"
          rules={[{ required: true, message: "Please input your contect!" }]}
        >
          <TextArea placeholder="" rows={4} id="936523_46780pi_936523_46780" name="936523_46780pi_936523_46780"></TextArea>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ marginTop: "10px" }}
            block
            type="primary"
            size="large"
            htmlType="submit"
            icon={<SendOutlined />}
          >
            Захидал илгээх
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SalesForceForm;

// const myStyle = {
//   backgroundColor: "#f6f6f6",
//   padding: "100px",
// };
// const onClick = {}
