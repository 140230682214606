import React, { useRef, useState } from "react";
import {
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Switch,
  Form,
  Button,
  message,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import { Editor } from "@tinymce/tinymce-react";

import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase-config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable
} from "firebase/storage";
import { async } from "@firebase/util";

// import tinymce from "tinymce/tinymce";

const EnterPostData = (props) => {
  const editorRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [coverUrl, setCoverUrl] = useState();
  const [showTags, setShowTags] = useState(true);
  const [progressVal, setProgressVal] = useState();

  // Writing data to firebase
  const PostsCollectionRef = collection(db, "posts");
  const createPost = async (payload) => {
    try {
      await addDoc(PostsCollectionRef, payload);
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      console.log(error.message);
      message.error("Амжилтгүй");
    }
  };

  const uploadImage = async (file) => {
    let imageUrl;
    const imageRef = ref(storage, `images/${file.name}`);
    const uploadTask = await uploadBytes(imageRef, file);
    imageUrl = await getDownloadURL(uploadTask.ref);
    return imageUrl;
  };

  const uploadThumbail = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `thumbnails/${file.name}`);
    try {
      let response = uploadBytesResumable(imageRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setThumbnailUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };

  const uploadCover = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `covers/${file.name}`);
    try {
      let response = uploadBytesResumable(imageRef, file);

      response.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress({ percent: progress });
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          await getDownloadURL(response.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setCoverUrl(downloadURL);
            onSuccess("Ok");
            return downloadURL;
          });
        }
      );

      return;
    } catch (error) {
      onError({ error });
    }
  };

  const onFinish = async (values) => {
    try {
      if (editorRef.current) {
        values.postBody = editorRef.current.getContent() || null;
      }
      values.postThumbnail = thumbnailUrl || null;
      values.postCover = coverUrl || null;
      values.postDate = values.postDate.format("YYYY-MM-DD");

      await createPost(values);

      form.resetFields();
      props.forceUpdate();
      props.onCancel();
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const onChange = async (values) => {
    if (values?.postCategory) {
      if (values?.postCategory == "CaseStudy") {
        setShowTags(false);
      } else {
        setShowTags(true);
      }
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          postStatus: true,
          postCategory: "PressNews",
          postTags: null,
        }}
        onValuesChange={onChange}
      >
        <Row gutter={[24, 24]}>
          <Col md={{ span: 17 }} sm={{ span: 24 }}>
            <Form.Item name="postTitle">
              <Input placeholder="Нийтлэлийн гарчиг" name="postTitle" />
            </Form.Item>
            <Form.Item>
              <Editor
                apiKey="gmyqqtvhl1sy9okzfgo00p917ljdiypq0oo94gff6zunj1a0"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue="<p>Нийтлэл оруулах хэсэг...</p>"
                init={{
                  menubar: true,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  plugins: "link image code table",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
                    "removeformat | image help",
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = async () => {
                        let imageUrl = await uploadImage(file);
                        cb(imageUrl, { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                    input.click();
                  },
                }}
              />
            </Form.Item>
          </Col>

          <Col md={{ span: 7 }} sm={{ span: 24 }}>
            <Form.Item name="postCategory">
              <Select
                defaultValue="PressNews"
                // onChange={handleChange}
              >
                <Option value="PressNews">Хэвлэлийн мэдээ</Option>
                <Option value="CaseStudy">Онцлох туршлага</Option>
                <Option value="SocialCare">Нийгмийн хариуцлага</Option>
              </Select>
            </Form.Item>
            <Form.Item name="postTags" hidden={showTags}>
              <Input placeholder="Түрхүүр үг" name="postTags" />
            </Form.Item>
            <Form.Item name="postDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="postStatus"
              label="Нийтлэл төлөв"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Идэвхитэй"
                unCheckedChildren="Идэвхигүй"
                defaultChecked={true}
              />
            </Form.Item>
            <Form.Item name="postThumbnail" valuePropName="fileList">
              <Upload
                listType="picture"
                maxCount={1}
                customRequest={uploadThumbail}
              >
                <Button icon={<UploadOutlined />}>Thumbnail (Max: 1)</Button>
              </Upload>
            </Form.Item>
            <Form.Item name="postCover" valuePropName="fileList">
              <Upload
                listType="picture"
                maxCount={1}
                customRequest={uploadCover}
              >
                <Button icon={<UploadOutlined />}>Ковер зураг оруулах</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EnterPostData;
