import React from 'react'

const SiteMap = () => {
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        backgroundColor: "rgba(0,0,0,.85)",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1
        style={{
          color:'#ccc'
        }}
      >
        Сайтын бүтэц
      </h1>
    </div>
  )
}

export default SiteMap
