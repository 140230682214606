import React from "react";
 import Testimonials from "./Career/Testimonials";
import { useEffect } from "react";
import CareerCover from "./Career/CareerCover";
const Career = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CareerCover   />


      <Testimonials />

       
    </>
  );
};

export default Career;
