import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import EditPostData from "./EditPostData";

const EditPost = (props) => {
  const [visible, setVisible] = useState(false);

  const showPopupEditPost = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        right: "50px",
        bottom: "50px",
      }}
    >
      <Button onClick={showPopupEditPost}>
        <PlusOutlined /> Нийтлэл засах
      </Button>
      <Modal
        centered
        visible={visible}
        title="Нийтлэл засах"
        width={1000}
        onCancel={onCancel}
        footer={null}
      >
        <EditPostData
          forceUpdate={props.forceUpdate}
          onCancel={onCancel}
          postId={props?.postId}
        />
      </Modal>
    </div>
  );
};

export default EditPost;
