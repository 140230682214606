import React, { useRef, useState } from "react";
import {
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Switch,
  Form,
  Button,
  message,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import { Editor } from "@tinymce/tinymce-react";

import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// import tinymce from "tinymce/tinymce";

const TestimonialForm = (props) => {
  const editorRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [showTags, setShowTags] = useState(true);

  // Writing data to firebase
  const TestimonialsCollectionRef = collection(db, "testimonials");
  const createTestimonial = async (payload) => {
    try {
      await addDoc(TestimonialsCollectionRef, payload);
    } catch (error) {
      console.log(error.message);
      message.error("Амжилтгүй");
    }
  };

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `images/${file.name}`);
    let response = await uploadBytes(imageRef, file);
    let imageUrl = await getDownloadURL(response.ref);
    return imageUrl;
  };

  const uploadThumbail = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const imageRef = ref(storage, `testimonial/${file.name}`);
    try {
      let response = await uploadBytes(imageRef, file);
      let imageUrl = await getDownloadURL(response.ref);
      setThumbnailUrl(imageUrl);
      onSuccess("Ok");
      return;
    } catch (error) {
      onError({ error });
    }
  };

  const onFinish = async (values) => {
    try {
      if (editorRef.current) {
        values.quote = editorRef.current.getContent() || null;
      }
      values.thumbnail = thumbnailUrl || null;

      await createTestimonial(values);
      message.success("Амжилттай нэмэгдлээ");

      form.resetFields();
      props.forceUpdate();
      props.onCancel();
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const onChange = async (values) => {
    if (values?.postCategory) {
      if (values?.postCategory == "CaseStudy") {
        setShowTags(false);
      } else {
        setShowTags(true);
      }
    }
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ postStatus: true, postCategory: "PressNews" }}
        onValuesChange={onChange}
      >
        <Row gutter={[24, 24]}>
          <Col md={{ span: 17 }} sm={{ span: 24 }}>
            <Form.Item name="jobTitle">
              <Input placeholder="Ажлын байр" name="jobTitle" />
            </Form.Item>
            <Form.Item>
              <Editor
                apiKey="gmyqqtvhl1sy9okzfgo00p917ljdiypq0oo94gff6zunj1a0"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                initialValue="<p>Сэтгэгдэл оруулах хэсэг...</p>"
                init={{
                  menubar: true,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  plugins: "link image code",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | image help",
                  file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.onchange = function () {
                      var file = this.files[0];

                      var reader = new FileReader();
                      reader.onload = async function () {
                        let imageUrl = await uploadImage(file);
                        cb(imageUrl, { title: file.name });
                      };
                      reader.readAsDataURL(file);
                    };
                    input.click();
                  },
                }}
              />
            </Form.Item>
          </Col>

          <Col md={{ span: 7 }} sm={{ span: 24 }}>
            <Form.Item name="name">
              <Input placeholder="Ажилтаны нэр" name="name" />
            </Form.Item>
            <Form.Item name="thumbnail">
              <Upload
                listType="picture"
                maxCount={1}
                customRequest={uploadThumbail}
              >
                <Button icon={<UploadOutlined />}>Thumbnail (Max: 1)</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TestimonialForm;
