import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Popconfirm } from "antd";
import { useAuth } from "../../Context/AuthContext";
import { auth } from "../../firebase/firebase-config";

const Footer = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  let email;
  let loggedIn;

  if (localStorage.getItem("auth")) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    email = auth.email;
    loggedIn = auth.loggedIn;
  }

  const onLogout = async () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <footer className="footer-area">
        <div className="centering-wrapper">
          <div className="footer-menu">
            <p>1997-2022 &copy; Mogul Group - Creating Value Together</p>
            <ul>
              <li>
                <Link to="/">Нүүр хуудас</Link>
              </li>
              {/* <li>
                <Link to="/securityTerms">Нууцлалын бодлого</Link>
              </li> */}
              <li>
                <Link to="/termsofuse">Үйлчилгээний нөхцөл</Link>
              </li>
              <li>
                <Link to="/sitemap">Сайтын бүтэц</Link>
              </li>
              {loggedIn ? (
                <li>
                  <Popconfirm
                    title="Та гарах уу?"
                    onConfirm={onLogout}
                    okText="Тийм"
                    cancelText="Үгүй"
                  >
                    <a href="#">Сайн уу? {email.split("@", -1)[0]}</a>
                  </Popconfirm>
                </li>
              ) : (
                <li>
                  <Link to="/administrator">Нэвтрэх</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
