import React, { useEffect } from "react";
import { Tabs, Timeline } from "antd";
import OverviewIntro from "./AboutUs/OverviewIntro";
import BoardTeam from "./AboutUs/BoardTeam";
import HistorycalTimeline from "./AboutUs/HistorycalTimeline";
import PageHeaderMogul from "../components/PageHeaderMogul";
import Mission from "./AboutUs/Mission";
import NumericStatsMogul from "./AboutUs/NumericStatsMogul";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeaderMogul imgurl="" title="Бидний Тухай" />
      <OverviewIntro />
      <NumericStatsMogul />
      <Mission />
      <BoardTeam />
      <HistorycalTimeline />
    </>
  );
};

export default AboutUs;
