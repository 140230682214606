import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { LoginOutlined } from "@ant-design/icons";
import logo from "./logo.svg";
import { useAuth } from "../../Context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const onFinish = async (values) => {
    try {
      const logged = await signInWithEmailAndPassword(
        auth,
        values.username,
        values.password
      );

      login(logged.user.email);
      message.success("Амжилттай нэвтэрлээ")
      navigate("/");
    } catch (error) {
      message.error("Нэр нууц үг буруу байна")
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F7",
        display: "flex",
        justifyContent: "center",
        padding: "150px 0",
        height: "100vh",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "50px 50px",
          display: "inline-block",
          height: "450px",
          borderTop: "1px solid #0555ff",
        }}
      >
        <img
          src={logo}
          alt="mogul"
          style={{
            height: "23px",
            marginBottom: "50px",
          }}
        />
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Нэвтрэх нэр"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Нууц үг"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Хэрэглэгч сануулах</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              icon={<LoginOutlined />}
            >
              Нэвтрэх
            </Button>
          </Form.Item>
        </Form>
        <p style={{ color: "#ccc" }}>
          *Зөвхөн эрх бүхий хэрэглэгчид хандах боломжтой!
        </p>
      </div>
    </div>
  );
};

export default Login;
