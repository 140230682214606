import React from "react";
import { Link } from "react-router-dom";
const FeaturedSmall = (props) => {
  const url = "/reading?id=" + props?.id;
  return (
    <Link to={url}>
      <div>
        <a href="#" className="FeaturedSmall">
          <h5
            style={{
              color: "blue",
            }}
          >
            {props.date}
          </h5>
          <h3>{props.title}</h3>
        </a>
      </div>
    </Link>

    // <div>
    //     <a href="#" className='FeaturedSmall'>
    //       <h5
    //       style={{
    //         color:'blue',
    //       }}>{props.date}</h5>
    //       <h3>{props.title}</h3>
    //     </a>
    // </div>
  );
};

export default FeaturedSmall;
